import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'
import BlockContent from '@sanity/block-content-to-react'
import { sanityConfig, serializers } from '../utils/helpers'
import Seo from '../components/seo'
import Layout from '../components/layout'

const groupNameConversions = {
  mentalHealth: 'Mental Health',
  knowingYourself: 'Knowing Yourself',
  spirituality: 'Spirituality',
  crisisResources: 'Crisis Resources',
  medicalProviders: 'Medical Providers/Therapists',
}

const resourceListItem = (node, edgeIndex = 0) => {
  let formattedUrl = node?.url
  if (node.url) {
    if (node.url.startsWith('tel:')) {
      formattedUrl = `Call ${node.url.substring(4)}`
    } else if (node.url.startsWith('mailto:')) {
      formattedUrl = `Email ${node.url.substring(7)}`
    }
  }
  return (
    <li key={edgeIndex}>
      <div className="h4">{node.title}</div>
      <div>{node.description}</div>
      {formattedUrl ? (
        <a href={node.url} target="_blank" rel="noopener noreferrer">
          {formattedUrl}
        </a>
      ) : (
        ''
      )}
    </li>
  )
}

const Resources = ({ data }) => {
  const page = data.sanityBasicPage
  const image = page?.mainImage?.image
  return (
    <Layout>
      <Seo title="Resources" />
      {image ? (
        <div className="sidebar-image image">
          <Image
            {...image}
            alt={page.mainImage.alt}
            style={{
              transform: 'rotateY(180deg)',
            }}
          />
        </div>
      ) : (
        ''
      )}
      <article>
        <h1>Resources</h1>
        <p />
        {page?._rawBody ? (
          <BlockContent
            blocks={page._rawBody}
            serializers={serializers}
            {...sanityConfig}
          />
        ) : (
          ''
        )}
        {data.authoredByMe.edges.length ? <h2>Authored by Me</h2> : ''}
        <ul>
          {data.authoredByMe.edges.map((edge, edgeIndex) =>
            resourceListItem(edge.node, edgeIndex)
          )}
        </ul>
        {data.knowingYourself.edges.length ? <h2>Knowing Yourself</h2> : ''}
        <ul>
          {data.knowingYourself.edges.map((edge, edgeIndex) =>
            resourceListItem(edge.node, edgeIndex)
          )}
        </ul>
        {data.spirituality.edges.length ? <h2>Spirituality</h2> : ''}
        <ul>
          {data.spirituality.edges.map((edge, edgeIndex) =>
            resourceListItem(edge.node, edgeIndex)
          )}
        </ul>
        <h2>Mental Health</h2>
        {data.mentalHealth.group.map((group, groupIndex) => (
          <div key={groupIndex}>
            <h3>{groupNameConversions[group.fieldValue]}</h3>
            <ul>
              {group.edges.map((edge, edgeIndex) =>
                resourceListItem(edge.node, edgeIndex)
              )}
            </ul>
          </div>
        ))}
      </article>
    </Layout>
  )
}

export default Resources

export const query = graphql`
  query ResourcesQuery {
    authoredByMe: allSanityResources(
      sort: { fields: orderRank }
      filter: { group: { eq: "authoredByMe" } }
    ) {
      edges {
        node {
          ...ResourceData
        }
      }
    }
    mentalHealth: allSanityResources(
      sort: { fields: orderRank }
      filter: { group: { eq: "mentalHealth" } }
    ) {
      group(field: subgroup) {
        edges {
          node {
            ...ResourceData
          }
        }
        fieldValue
      }
    }
    knowingYourself: allSanityResources(
      sort: { fields: orderRank}
      filter: { group: { eq: "knowingYourself" } }
    ) {
      edges {
        node {
          ...ResourceData
        }
      }
    }
    spirituality: allSanityResources(
      sort: { fields: orderRank }
      filter: { group: { eq: "spirituality" } }
    ) {
      edges {
        node {
          ...ResourceData
        }
      }
    }
    sanityBasicPage(_id: { eq: "resources" }) {
      title
      _rawBody
      mainImage {
        alt
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
